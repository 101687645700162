import { Controller } from '@hotwired/stimulus'
import Dropzone from 'dropzone'
import { headers } from '../utils'
import Toastify from 'toastify-js'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.dropzone = new Dropzone(this.element, {
      url: this.data.get('url'),
      clickable: this.inputTarget,
      acceptedFiles: '.pdf,.docx,.txt,.csv',
      paramName: 'document[files][]',
      headers: headers(),
      autoProcessQueue: true,
      previewsContainer: '.dz-main-div',
      previewTemplate: `
                <div class="file-preview p-2 border rounded mr-2 text-center max-w-[100px]">
                    <div class="px-4 file-type-indicator block mx-auto w-6 h-10 bg-e2e8f0 bg-contain bg-no-repeat bg-center"></div>
                    <span class="file-name block text-xs overflow-hidden truncate text-ellipsis text-gray-500"></span>
                </div>
            `,

      accept: function (file, done) {
        const fileExtension = file.name.split('.').pop().toLowerCase()

        if (['pdf', 'docx', 'txt', 'csv'].includes(fileExtension)) {
          done()
        } else {
          done('Unsupported file type')
        }
      },
    })

    this.dropzone.on('error', (file, response) => {
      Toastify({
        text: response.error || 'Something went wrong!',
        duration: 15000,
        close: true,
        gravity: 'bottom',
        position: 'right',
        style: {
          background: 'linear-gradient(to right, rgba(244,67,54,0.8), rgba(158,11,0,1))',
          color: 'white',
          borderRadius: '8px',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
        },
        stopOnFocus: true,
      }).showToast()

      this.dropzone.removeFile(file)
    })

    this.dropzone.on('addedfile', (file) => {
      let typeIndicator = file.previewElement.querySelector('.file-type-indicator')
      switch (file.type) {
        case 'application/pdf':
          typeIndicator.classList.add('file-icon-pdf')
          break
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          typeIndicator.classList.add('file-icon-docx')
          break
        case 'text/plain':
          typeIndicator.classList.add('file-icon-txt')
          break
        case 'text/csv':
          typeIndicator.classList.add('file-icon-csv')
          break
      }

      file.previewElement.querySelector('.file-name').textContent = file.name

      this.dropzone.processQueue()
    })

    this.dropzone.on('success', function (file, response) {
      if (response.message) {
        Toastify({
          text: response.message,
          duration: 15000,
          close: true,
          gravity: 'bottom',
          position: 'right',
          style: {
            background: 'linear-gradient(to right, rgba(58,65,111,0.8), rgba(20,23,39,1))',
            color: 'white',
            borderRadius: '8px',
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          },
          stopOnFocus: true,
        }).showToast()

        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
    })
  }
}
