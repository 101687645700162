import { Controller } from "@hotwired/stimulus"
import { Datepicker } from 'flowbite'

// Connects to data-controller="datepicker"
export default class extends Controller {
  static targets = [ "element" ]
  connect() {
    const instanceOptions = {
      id: 'datepicker-meetings',
      override: true,
    }
    const options = {
      autohide: false,
      format: 'mm/dd/yyyy',
      orientation: 'bottom',
      rangePicker: false
    }

    this.datepicker = new Datepicker(this.elementTarget, options, instanceOptions)
  }

  change() {
    const date = this.datepicker.getDate()
    const newDate = new Date(Date.parse(this.element.dataset.date))

    if (date.getFullYear() === newDate.getFullYear() && date.getMonth() === newDate.getMonth() && date.getDate() === newDate.getDate()) {
      console.log('Same date')
    } else {
      const parsedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      window.location.assign(`/meetings?date=${parsedDate}`)
    }
  }
}
