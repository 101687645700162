import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
  static values = { maxItems: Number };

  connect() {
    new TomSelect(this.element, {
      maxItems: this.maxItemsValue || 6, // Default to 6 if maxItemsValue is not set
      plugins: ['remove_button'],
      persist: false,
      create: false,
      sortField: {
        field: 'text',
        direction: 'asc',
      },
      placeholder: 'Search and select actions...',
      onItemAdd: () => {
        if (
          this.element.tomselect.getValue().length >
          this.maxItemsValue
        ) {
          alert(
            `You can only select up to ${this.maxItemsValue} quick actions.`
          );
          this.element.tomselect.removeItem(
            this.element.tomselect.getValue().slice(-1)[0]
          ); // Remove the last selected item
        }
      },
    });
  }
}
