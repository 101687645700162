import { Controller } from "@hotwired/stimulus"
import React from 'react'
import ReactDOM from 'react-dom/client'
import CarbonConnectComponent from '../components/CarbonConnectComponent'

// Connects to data-controller="knowledge"
export default class extends Controller {
  static values = {
    customerId: String,
    brandIcon: String
  }

  connect() {
    this.appElement = document.getElementById('knowledge')
  }

  openModal() {
    if (this.appElement) {
      const root = ReactDOM.createRoot(this.appElement);
      root.render(<CarbonConnectComponent
          customerId={this.customerIdValue}
          brandIcon={this.brandIconValue}
      />);
    }
  }
}
