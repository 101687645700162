import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio"
export default class extends Controller {
  static targets = [ "toShow" ]
  connect() {
  }

  hideTarget() {
    this.toShowTarget.classList.add('hidden')
  }

  showTarget() {
    this.toShowTarget.classList.remove('hidden')
  }
}
