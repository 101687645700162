// app/javascript/controllers/expand_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['expandArea', 'icon'];

  toggle() {
    this.expandAreaTarget.classList.toggle('hidden')

    // Toggle the arrow icon
    if (this.iconTarget.classList.contains('fa-chevron-down')) {
      this.iconTarget.classList.remove('fa-chevron-down');
      this.iconTarget.classList.add('fa-chevron-up');
    } else {
      this.iconTarget.classList.remove('fa-chevron-up');
      this.iconTarget.classList.add('fa-chevron-down');
    }
  }
}
