import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['search', 'sort'];

  connect() {
    this.timeout = null;
  }

  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.updateIntegrations();
    }, 300);
  }

  sort() {
    this.updateIntegrations();
  }

  reset() {
    this.searchTarget.value = '';
    this.sortTarget.value = '';
    this.updateIntegrations();
  }

  updateIntegrations() {
    const search = this.searchTarget.value;
    const sort = this.sortTarget.value;

    fetch(`/integrations?search=${search}&sort=${sort}`, {
      headers: { 'Turbo-Frame': 'integrations_frame' },
    })
      .then((response) => response.text())
      .then((html) => {
        document.getElementById('integrations_frame').innerHTML =
          html;
      });
  }
}
