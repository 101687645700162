import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["checkButton", "uncheckButton", "hide"]
  static values = { hide: String }

  connect() {
  }

  click(event) {
    console.log(event.target.checked)
    if (event.target.checked) {
      this.checkButtonTarget.click()
    } else {
      if (this.hasUncheckButtonTarget) {
        this.uncheckButtonTarget.click()
      } else {
        document.querySelector(this.hideValue).innerHTML = ""
      }
    }
  }
}
